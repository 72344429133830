import React from 'react';
import ReactDOM from 'react-dom/client';
//import Registro from './components/Registro';
import Router from './components/Router';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <Router/>);


//reportWebVitals();
