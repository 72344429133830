import febrero from './febrero';
import marzo from './marzo';
import abril from './abril';
import mayo from './mayo';
import junio from './junio';
import julio from './julio';
import agosto from './agosto';
import septiembre from './septiembre';
import octubre from './octubre';
import noviembre from './noviembre';
import diciembre from './diciembre';


function todosLosMeses(Data) {
    febrero(Data)
    marzo(Data)
    abril(Data)
    mayo(Data)
    junio(Data)
    julio(Data)
    agosto(Data)
    septiembre(Data)
    octubre(Data)
    noviembre(Data)
    diciembre(Data)
}

export default todosLosMeses